import { useCallback } from "react";

const useTypedData = () => {
  const typedData = useCallback((nftAddress, tokenId, tokenAddress, price) => {
    const data = {
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" }
        ],
        Detail: [
          { name: "NFTAddress", type: "address" },
          { name: "TokenId", type: "uint256" },
          { name: "PayToken", type: "address" },
          { name: "Price", type: "uint256" }
        ]
      },
      domain: {
        chainId: process.env.REACT_APP_CHAIN_ID,
        name: "MBH Marketplace",
        version: "1"
      },
      primaryType: "Detail",
      value: {
        NFTAddress: nftAddress,
        TokenId: tokenId,
        PayToken: tokenAddress,
        Price: price
      }
    };
    return data;
  }, []);
  return typedData;
};

export default useTypedData;
